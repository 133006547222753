import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api/embed';
import eventBus from '@/plugins/event-bus';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeEpisode: null,
    activePodcast: null,
    embedSettings: null,
  },
  getters: {
    activeEpisode: state => state.activeEpisode,
    activePodcast: state => state.activePodcast,
    embedSettings: state => state.embedSettings,
  },
  mutations: {
    SET_ACTIVE_EPISODE(state, payload) {
      state.activeEpisode = payload;
      state.embedSettings = payload.embed_settings;
      eventBus.$emit('embedSettingsSet');
    },
    SET_ACTIVE_PODCAST(state, payload) {
      state.activePodcast = payload;
      state.embedSettings = payload.embed_settings;
      eventBus.$emit('embedSettingsSet');
    },
  },
  actions: {
    async getEmbedEpisode({ commit }, id) {
      const { data, success, message } = await api.getEmbedEpisode(id);
      if (success) {
        commit('SET_ACTIVE_EPISODE', data);
      }
      return { success, message };
    },

    async getEmbedPodcast({ commit }, code) {
      const { data, success, message } = await api.getEmbedPodcast(code);
      if (success) {
        commit('SET_ACTIVE_PODCAST', data);
      }
      return { success, message };
    },
  },
});
