import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/embed/podcasts/:podcastCode',
    name: 'EmbedPodcast',
    component: () => import(/* webpackChunkName: "podcastEmbed" */ '../views/EmbedPodcast.vue'),
  },
  {
    path: '/embed/episodes/:episodeId',
    name: 'EmbedEpisode',
    component: () => import(/* webpackChunkName: "episodeEmbed" */ '../views/EmbedEpisode.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
