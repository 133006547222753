<template>
  <div id="app">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import {
  getPrimaryColor,
  getSecondaryColor,
  getFontColor,
  getAccessibleColor,
  getRGBColor,
} from '@/utils/helpers';
import eventBus from '@/plugins/event-bus';

export default {
  name: 'App',
  computed: {
    primaryColor() {
      return getRGBColor(getPrimaryColor(), 'primary');
    },
    a11yColor() {
      return getRGBColor(getAccessibleColor(getPrimaryColor()), 'a11y');
    },
    secondaryColor() {
      return getRGBColor(getSecondaryColor(), 'secondary');
    },
    getFontColor() {
      return getRGBColor(getFontColor(), 'font');
    },
  },

  mounted() {
    eventBus.$on('embedSettingsSet', () => {
      const root = document.querySelector(':root');
      var primaryColor = this.primaryColor.split(':');
      var a11yColor = this.a11yColor.split(':');
      var secondaryColor = this.secondaryColor.split(':');
      var getFontColor = this.getFontColor.split(':');
      root.style.setProperty(primaryColor[0], primaryColor[1]);
      root.style.setProperty(a11yColor[0], a11yColor[1]);
      root.style.setProperty(secondaryColor[0], secondaryColor[1]);
      root.style.setProperty(getFontColor[0], getFontColor[1]);
    });
  },
};
</script>
