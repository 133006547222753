import API from '@/api';

async function getEmbedEpisode(id) {
  const response = await API.get(`embed/episodes/${id}`);
  return response.data;
}

async function getEmbedPodcast(code) {
  const response = await API.get(`embed/podcasts/${code}`);
  return response.data;
}

async function getEmbedPodcastEpisodes(code, params) {
  const response = await API.get(`embed/podcasts/${code}/episodes`, params);
  return response.data;
}

async function getEpisodePeaksData(id) {
  const response = await API.get(`embed/episodes/${id}/peaks-data`);
  return response.data;
}

export default {
  getEmbedEpisode,
  getEmbedPodcast,
  getEmbedPodcastEpisodes,
  getEpisodePeaksData,
};
