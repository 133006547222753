import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/css/app.css';
import VTooltip from 'v-tooltip';
import Spinner from './components/shared/Spinner.vue';

Vue.use(VTooltip);

/** VUE GLOBAL COMPONENTS */
Vue.component('Spinner', Spinner);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
